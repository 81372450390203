<template>
	<div class="box">
		<div class="null-page" v-show="yes"></div>

		<el-card class="box-card">
			<div slot="header" class="clearfix">
				<span>Edit account</span>
			</div>

			<div v-loading="loading">
				<el-form :model="formData" :rules="rules" ref="ruleForm" label-width="80px">
					<el-form-item label="Name" prop="realname">
						<el-input v-model="formData.realname" placeholder="Please enter your real name" class="ns-len-input"></el-input>
					</el-form-item>

					<el-form-item label="Mobile Phone" prop="mobile">
						<el-input v-model="formData.mobile" autocomplete="off" placeholder="Please enter your mobile phone number" maxlength="11" class="ns-len-input"></el-input>
					</el-form-item>

					<el-form-item label="account type" prop="withdraw_type">
						<el-select v-model="formData.withdraw_type" placeholder="Please select account type">
							<el-option v-for="(value, key) in transferType" :key="key" :label="value" :value="key" :disabled="key == 'wechatpay'"></el-option>
						</el-select>
					</el-form-item>

					<el-form-item label="Bank name" prop="branch_bank_name" v-if="formData.withdraw_type == 'bank'">
						<el-input v-model="formData.branch_bank_name" autocomplete="off" placeholder="Please enter bank name" maxlength="50" class="ns-len-input"></el-input>
					</el-form-item>

					<el-form-item label="Withdrawal account number" prop="bank_account" v-if="formData.withdraw_type != 'wechatpay' && formData.withdraw_type">
						<el-input v-model="formData.bank_account" autocomplete="off" placeholder="Please enter the withdrawal account number" maxlength="30" class="ns-len-input"></el-input>
					</el-form-item>

					<el-form-item>
						<el-button size="medium" type="primary" @click="saveAccount('ruleForm')">Save</el-button>
					</el-form-item>
				</el-form>
			</div>
		</el-card>
	</div>
</template>

<script>
	import { transferType, accountDetail, saveAccount } from "@/api/member/member"
	export default {
		name: "account_edit",
	    components: {},
	    data () {
			let self = this
			var isMobile = (rule, value, callback) => {
			    if (!value) {
			        return callback(new Error("Mobile phone number cannot be empty"))
			    } else {
			        const reg = /^(\+233|0)(20|50|24|54|27|57|26|56|23|28|55|59)\d{7}$/

			        if (reg.test(value)) {
			            callback()
			        } else {
			            callback(new Error("Please enter the correct mobile phone number"))
			        }
			    }
			}
	        return {
				formData: {
					id: '',
					realname: '',
					mobile: '',
					withdraw_type: '',
					bank_account: '',
					branch_bank_name: ''
				},
				flag: false, //防重复标识
				payList: [],
				loading: true,
				index: 0,
				transferType: [],
				rules: {
					realname: [{ required: true, message: "Please enter your real name", trigger: "blur" }],
					mobile: [{ required: true, validator: isMobile, trigger: "blur" }],
					withdraw_type: [{ required: true, message: 'Please select account type', trigger: 'change' }],
					branch_bank_name: [{ required: true, message: "Please enter bank name", trigger: "blur" }],
					bank_account: [{ required: true, message: "Please enter the withdrawal account number", trigger: "blur" }],
				},
				yes: true
	        }
	    },
	    created() {
			this.formData.id = this.$route.query.id
			this.getTransferType();
			if (this.formData.id) {
				this.getAccountDetail(this.formData.id)
			}
	    },
		mounted() {
			let self = this;
			setTimeout(function() {
				self.yes = false
			}, 300)
		},
	    methods: {
			/**
			 * 获取转账方式
			 */
			getTransferType() {
				transferType().then(res => {
					this.transferType = res.data;

					if (!this.formData.id) { // id为空，即添加时在此结束加载
						this.loading = false
					}
				}).catch(err => {
					if (!this.formData.id) {
						this.loading = false
					}
				})
			},
			/**
			 * 获取账户详情（编辑）
			 */
			getAccountDetail(id) {
				accountDetail({id: this.formData.id}).then(res => {
					if (res.code == 0 && res.data) {
						this.formData.realname = res.data.realname;
						this.formData.mobile = res.data.mobile;
						this.formData.bank_account = res.data.bank_account;
						this.formData.branch_bank_name = res.data.branch_bank_name;
						this.formData.withdraw_type = res.data.withdraw_type;
					}
					this.loading = false
				}).catch(err => {
					this.loading = false
				})
			},
			/**
			 * 保存
			 */
			saveAccount(formName) {
				this.$refs[formName].validate(valid => {
				    if (valid) {
				        var data = {
							id: this.formData.id,
							realname: this.formData.realname,
							mobile: this.formData.mobile,
							withdraw_type: this.formData.withdraw_type,
							bank_account: this.formData.bank_account,
							branch_bank_name: this.formData.branch_bank_name
				        }

				        data.url = "add"
				        if (this.formData.id) {
				            data.url = "edit"
				        }

				        if (this.flag) return
				        this.flag = true

				        saveAccount(data)
				            .then(res => {
				                if (res.code == 0) {
				                    this.$router.push({ path: "/member/account_list" })
				                } else {
				                    this.flag = false
				                    this.$message({ message: res.message, type: "warning" })
				                }
				            })
				            .catch(err => {
				                this.flag = false
				                this.$message.error(err.message)
				            })
				    } else {
				        return false
				    }
				})
			}
		}
	}
</script>

<style lang="scss" scoped>
	.box {
		width: 100%;
		position: relative;
	}

	.null-page {
		width: 100%;
		height: 730px;
		background-color: #FFFFFF;
		position: absolute;
		top: 0;
		left: 0;
		z-index: 9;
	}

	.el-card.is-always-shadow,
	.el-card.is-hover-shadow:focus,
	.el-card.is-hover-shadow:hover {
	    box-shadow: unset;
	}

	.el-card {
	    border: 0;
	}

	.ns-len-input {
	    width: 350px;
	}

	.el-select {
	    margin-right: 10px;
	}
</style>
